import "./Header.css"
import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import SocialMediaButton from "./SocialMediaButton";

import studio_icon from '../media/studio_icon.png';
import studio_text from '../media/studio_text.png';


function Header() {
	const [activeSection, setActiveSection] = useState('');

	useEffect(() => {
		const sections = document.querySelectorAll('section');
		const options = {
			threshold: 0.3
		};

		const observer = new IntersectionObserver((entries, observer) => {
			entries.forEach(entry => {
				if (entry.isIntersecting) {
					setActiveSection(entry.target.id);
				}
			});
		}, options);

		sections.forEach(section => {
			observer.observe(section);
		});

		return () => {
			sections.forEach(section => {
				observer.unobserve(section);
			});
		};
	}, []);


	return (
		<Navbar className="custom-navbar" fixed="top" data-bs-theme="dark">
			<Container>
				<Navbar.Collapse className="justify-content-left">
					<Nav>
						<Navbar.Brand href="#home" className="navbar-title">
							<img src={studio_icon} alt="" className="studio-icon" />
							<img src={studio_text} alt="" className="studio-top" />
						</Navbar.Brand>
					</Nav>
				</Navbar.Collapse>

				<Navbar.Collapse className="justify-content-end" id="links">
					<Nav>
						<Nav.Item>
							<Nav.Link 
								href="#games" 
								className={activeSection === 'games' ? 'active' : ''} 
							>
								GAMES
							</Nav.Link>
						</Nav.Item>
						
						<Nav.Item>
							<Nav.Link 
								href="#company" 
								className={activeSection === 'company' ? 'active' : ''} 
							>
								COMPANY
							</Nav.Link>
						</Nav.Item>

						<Nav.Item>
							<Nav.Link 
								href="#team" 
								className={activeSection === 'team' ? 'active' : ''} 
							>
								TEAM
							</Nav.Link>
						</Nav.Item>
						
						<Nav.Item>
							<Nav.Link 
								href="#contact" 
								className={activeSection === 'contact' ? 'active' : ''} 
							>
								CONTACT
							</Nav.Link>
						</Nav.Item>
						
						<Nav.Item style={{ color: "rgb(0,0,0,0)"}}>
							{"hi:)"}
						</Nav.Item>

						<Nav.Item style={{ fontSize: "0.7rem" }}>
							<SocialMediaButton
								platform="jobs"
								text="jobs"
								link="https://docs.google.com/document/d/10RrUIhRodGFx5idooXgjVfsehkhtn2OkkAWJgYh3jZs/edit?tab=t.0#heading=h.mgihdc1w2i2z" 
							/>
						</Nav.Item>
					</Nav>
				</Navbar.Collapse>
			</Container>
		</Navbar>
	);
}

export default Header;
