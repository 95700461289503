import "react-bootstrap";
import "./App.css";
import Header from "./components/Header.jsx";
import Footer from "./components/Footer.jsx";
import SocialMediaButton from "./components/SocialMediaButton.jsx";
import ImageCarousel from "./components/ImageCarousel.jsx";
import TeamMember from "./components/TeamMember.jsx";
import ContactForm from "./components/ContactForm.jsx";

import home_logo from "./media/home_logo.png";
import home_background from "./media/home_background_video.webm";
import studio_icon from "./media/studio_icon.png";
import game_logo from "./media/game_logo.png";
import screenshot1 from "./media/game_screenshot.png";
import screenshot2 from "./media/game_screenshot2.png";
import screenshot3 from "./media/home_background.png";

import { FaDiscord, FaYoutube, FaSteam, FaPlay, FaAngleDown } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

import React, { useState, useEffect } from "react";
import teamData from "./team.json";

function App() {
	useEffect(() => {
		if (window.location.pathname === "/jobs") {
		    window.location.href = "https://docs.google.com/document/d/10RrUIhRodGFx5idooXgjVfsehkhtn2OkkAWJgYh3jZs/edit?tab=t.0#heading=h.mgihdc1w2i2z";
		}
	 }, []);	 

	// Reading team.json and creating the TeamMember components
	const [teamMembers, setTeamMembers] = useState([]);
	useEffect(() => {
		setTeamMembers(teamData);
	}, []);

	// Scroll-down arrow on home section
	const [scrollOpacity, setScrollOpacity] = useState(1);
	useEffect(() => {
		const handleScroll = () => {
			const scrollTop = window.scrollY;
			const maxScroll = 400; // adjust this value to control when the arrow fully disappears
			const newOpacity = Math.max(1 - scrollTop / maxScroll, 0);
			setScrollOpacity(newOpacity);
		};
		window.addEventListener("scroll", handleScroll);
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);
	const handleArrowClick = () => {
		const nextSection = document.getElementById("games");
		if (nextSection) {
			nextSection.scrollIntoView({ behavior: 'smooth' });
		}
	};

	return (
		<div className="App">
			<header id="header">
				<Header />
			</header>

			<section id="home">
				<video
					autoPlay
					loop
					muted
					id="home-background-video"
					>
					<source src={home_background} type="video/webm" />
				</video>
				<img
					src={home_logo}
					alt=""
					className="home-logo"
				/>
				<h3 
					style={{ paddingBottom: "80px" }}
					className="home-subtitle">
					RELEASING IN 2026.
				</h3>
				<div className="social-buttons-container">
					<SocialMediaButton
						platform="steam"
						text="WISHLIST ON STEAM"
						link="https://store.steampowered.com/app/2707670/SCP_Valravn/"
					/>
					<SocialMediaButton
						platform="discord"
						text="JOIN THE DISCORD"
						link="https://discord.gg/scpvalravn"
					/>
				</div>

				<div id="home-down-arrow" style={{ opacity: scrollOpacity }} onClick={handleArrowClick}>
					<FaAngleDown size={50} />
				</div>

				<div id='home-video-link'>
					<a href="https://youtu.be/rfezrcMo3H4" target="_blank" rel="noopener noreferrer">
						<FaPlay style={{paddingBottom: "4px"}}/> WATCH TRAILER
					</a>
				</div>
			</section>

			<section id="games">
				<div className="section-header">
					<img
						src={studio_icon}
						alt=""
						className="section-header-icon"
					/>
					<h2>OUR GAMES</h2>
				</div>
				<div className="section-header" id="section-header-games" style={{ gap: "50px" }}>
					<img
						src={game_logo}
						alt=""
						className="game-logo"
					/>
					<div>
						<h3 style={{ fontSize: "1.6rem", paddingBottom: "10px" }}>
							SCP: Valravn releases in 2026.
						</h3>
						<p className="game-description">
							<b>Alien: Isolation</b> meets <b>Trepang2</b> in one of the biggest commercial video games based on the popular SCP Foundation universe. You play as Leena Sorvoja — an elite combat operative of the Global Occult Coalition, faced with a mission to save her father from the eldritch horrors unleashed by the Valravn Corporation, a PMC group hell-bent on using anomalies to take over the world.
						</p>
						<div
							className="social-buttons-container"
							id="game-socials"
							style={{ justifyContent: "left" }}
						>
							<SocialMediaButton
								platform="steam"
								link="https://store.steampowered.com/app/2707670/SCP_Valravn/"
							/>
							<SocialMediaButton
								platform="discord"
								link="https://discord.gg/scpvalravn"
							/>
							<SocialMediaButton
								platform="youtube"
								text="Gameplay Trailer"
								link="https://youtu.be/rfezrcMo3H4"
							/>
						</div>
					</div>
				</div>
				<div style={{ paddingTop: "5vh" }}>
					<ImageCarousel images={[screenshot1, screenshot2, screenshot3]} />
				</div>
			</section>

			<section id="company" style={{ minHeight: "70vh"}}>
				<div className="section-header">
					<img
						src={studio_icon}
						alt=""
						className="section-header-icon"
					/>
					<h2>OUR COMPANY</h2>
				</div>
				<div className="section-content">
					<h3>ABOUT US</h3>
					<p>
						Subtle Bravado Studios is a fully remote game studio based in Croatia.
						It was officially founded in mid 2024 by game developer Nikk F, most well known for
						creating SCP: Ground Zero on Steam and The Arrowhead Incident on itch.io.
						The full history of our studio goes back to May of 2023, when a new SCP project
						was started and a team was put together to help create it. The team at the time
						originally only consisted of 3 members; a programmer, viewmodel animator and game designer.
						The game prototype soon attracted more members and the team grew to 10 members.
						Now, after officially becoming a company and studio, it has 20 employees from all around the world.
						We partnered with Critical Reflex shortly after our inception to help create our biggest project yet;
						SCP: Valravn.
					</p>
					<div className="section-separator"></div>
					<div className="social-icons-container">
						<a
							href="https://x.com/NikkF__"
							aria-label="Twitter"
							target="_blank"
							rel="noopener noreferrer"
						>
							<FaXTwitter />
						</a>
						<a
							href="https://discord.gg/scpvalravn"
							aria-label="Discord"
							target="_blank"
							rel="noopener noreferrer"
						>
							<FaDiscord />
						</a>
						<a
							href="https://www.youtube.com/@SubtleBravadoStudios"
							aria-label="YouTube"
							target="_blank"
							rel="noopener noreferrer"
						>
							<FaYoutube />
						</a>
						<a
							href="https://store.steampowered.com/app/2707670/SCP_Valravn/"
							aria-label="Steam"
							target="_blank"
							rel="noopener noreferrer"
						>
							<FaSteam />
						</a>
					</div>
				</div>
			</section>

			<section id="team">
				<div className="section-header" style={{ marginBottom: "8em" }}>
					<img
						src={studio_icon}
						alt=""
						className="section-header-icon"
					/>
					<h2>OUR TEAM</h2>
				</div>

				<div className="team-main-member">
					{teamMembers.length > 0 && (
						<TeamMember
							icon={teamMembers[0].icon}
							fullname={teamMembers[0].fullname}
							role={teamMembers[0].role}
							socialLinks={teamMembers[0].socialLinks}
						/>
					)}
				</div>

				<div className="team-grid">
					{teamMembers.slice(1).map((member, index) => (
						<TeamMember
							key={index}
							icon={member.icon}
							fullname={member.fullname}
							role={member.role}
							socialLinks={member.socialLinks}
						/>
					))}
				</div>
			</section>

			<section id="contact" style={{ minHeight: "60vh" }}>
				<div className="section-header">
					<img
						src={studio_icon}
						alt=""
						className="section-header-icon"
					/>
					<h2>CONTACT US</h2>
				</div>

				<ContactForm />
			</section>

			<Footer />
		</div>
	);
}

export default App;
