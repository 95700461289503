// SocialMediaButton.jsx
import React from 'react';
import { FaYoutube, FaTwitch, FaDiscord, FaSteam, FaXbox, FaPlaystation } from 'react-icons/fa'; // Import icons you need
import { FaXTwitter } from "react-icons/fa6";
import './SocialMediaButton.css';

const SocialMediaButton = ({ platform, text, link}) => {
     const icons = {
          youtube: <FaYoutube />,
          twitch: <FaTwitch />,
          twitter: <FaXTwitter />,
          discord: <FaDiscord />,
          steam: <FaSteam />,
          xbox: <FaXbox />,
          playstation: <FaPlaystation />,
          jobs: null
     };

     const colors = {
          youtube: '#ff0033',
          twitch: '#9146ff',
          twitter: '#000000',
          discord: '#2B3BE2',
          steam: '#0B89FF',
          xbox: '#0F780F',
          playstation: '#003792',
          jobs: '#0B89FF'
     };

     const displayText = text || platform;

     return (
          <a href={link} target="_blank" rel="noopener noreferrer">
               <button className="social-media-button" style={{ backgroundColor: colors[platform] }}>
                    { icons[platform] != null && 
                    <div className="social-media-icon">
                         {icons[platform]}
                    </div>
                    }
                    <span className="social-media-name">{displayText.toUpperCase()}</span>
               </button>
          </a>
     );
};

export default SocialMediaButton;
